<template>
  <div class="p-8">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal('close')"
    />
    <h1 class="text-xl text-center">
      Journaliser i Acadre
    </h1>
    <div
      v-if="acadreConnectionStatus"
      class="py-2"
    >
      <table class="w-full">
        <tr>
          <td class="w-1/2 table-line-seperator">
            <div class="flex my-2">
              <label
                for="acadre-case-type"
                class="w-1/3 mt-2 ml-4 text-sm font-medium"
              >
                Sagstype:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.case_file_status_code"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.case_file_status_code"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <multiselect
                id="acadre-case-type"
                v-model="acadreCaseData.case_file_type_code"
                class="mr-4"
                name="acadre-case-type"
                :options="acadreOptions.caseType"
                :custom-label="customAcadreLabel"
                :multiple="false"
                :allow-empty="false"
                :searchable="false"
                :close-on-select="true"
                placeholder="Vælg sagstype"
                :option-height="150"
                select-label
                deselect-label
                selected-label
                @update:model-value="onCaseTypeChanged"
              />
            </div>
          </td>
          <td class="w-1/2">
            <div class="flex my-2">
              <label
                for="acadre-case-title"
                class="w-1/3 mt-2 ml-4 text-sm"
              >
                Sagsstatus:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.case_file_status_code"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.case_file_status_code"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <multiselect
                id="acadre-case-status"
                v-model="acadreCaseData.case_file_status_code"
                class="mr-4"
                name="acadre-case-status"
                title="Skal være underbehandling for at tilføje dokumenter på"
                :options="acadreOptions.caseStatus"
                :custom-label="customAcadreLabel"
                :multiple="false"
                :allow-empty="false"
                :searchable="false"
                :close-on-select="true"
                placeholder="Vælg sagsstatus"
                :option-height="150"
                select-label
                deselect-label
                selected-label
                disabled
              />
            </div>
          </td>
        </tr>
      </table>
      <table class="w-full">
        <tr> 
          <td class="w-1/2">
            <!-- If Borgersagstype -->
            <div 
              v-if="caseTypeNeedsPerson()"
              class="flex my-2"
            >
              <label
                for="acadre-case-title"
                class="mt-2 ml-4 w-1/3 text-sm font-medium"
              >
                CPR. Nr:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.case_file_title_text"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.case_file_title_text"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <input
                id="acadre-case-title"
                v-model="acadreCaseData.case_file_title_text"
                class="mr-4 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                type="text"
                placeholder="CPR Nr. på part"
                name="acadre-case-title"
                :disabled="editDisabled"
              >
            </div>
            <!-- Else If Ejendomssagtype -->
            <div 
              v-else-if="caseTypeNeedsProperty()"
              class="flex my-2"
            >
              <label
                for="adress"
                class="mr-6 ml-4 text-sm font-medium"
              >
                DAR Ejendom:
              </label>
              <standard-button
                button-type="standard"
                button-text="Søg adresse"
                title="adress"
                class="btn-fixed-width ml-4 mb-0 md:mr-2 md:mb-1 sm:mb-2"
                button-text-small
                @click="openAddressModal()"
              />
            <!-- 
              <label
                for="acadre-case-title"
                class="mt-2 ml-4 w-1/3 text-sm font-medium"
              >
                BFE-/ Ejendomsnr.:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.case_file_title_text"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.case_file_title_text"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <input
                id="acadre-case-title"
                v-model="acadreCaseData.case_file_title_text"
                class="mr-4 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                type="text"
                placeholder="Ejendomsnr. på part"
                name="acadre-case-title"
                :disabled="editDisabled"
              > -->
            </div>
            <!-- Else Emnesagstype -->
            <div 
              v-else 
              class="flex my-2"
            >
              <label
                for="acadre-case-title"
                class="mt-2 ml-4 w-1/3 text-sm font-medium"
              >
                Sagstitel:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.case_file_title_text"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.case_file_title_text"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <input
                id="acadre-case-title"
                v-model="acadreCaseData.case_file_title_text"
                class="mr-4 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                type="text"
                placeholder="Titel på sag"
                name="acadre-case-title"
              >
            </div>
          </td>
          <td class="w-1/2">
            <!-- Sagsansvarlig Bruger -->
            <div class="flex my-2">
              <label
                for="acadre-user-list"
                class=" ml-4 w-1/3 text-sm font-medium"
              >
                Sagsansvarlig bruger:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.case_file_manager_reference"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.case_file_manager_reference"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <multiselect
                id="acadre-user-list"
                v-model="acadreCaseData.case_file_manager_reference"
                class="mr-4"
                name="acadre-user-list"
                :options="acadreOptions.userList"
                :custom-label="customUserListAcadreLabel"
                :multiple="false"
                :allow-empty="false"
                :searchable="true"
                :close-on-select="true"
                placeholder="Vælg ansvarlig bruger"
                :option-height="150"
                select-label
                deselect-label
                selected-label
                @update:model-value="onUserSelected"
              />
            </div>
          </td>
        </tr>
        <tr> 
          <td class="w-1/2">
            <!-- If Borgersagstype 2 -->
            <div
              v-if="caseTypeNeedsPerson()" 
              class="flex my-2"
            >
              <label
                for="acadre-alternative-title"
                class="mt-2 w-1/3 ml-4 text-sm font-medium"
              >
                Navn/ Bemærkning:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.title_alternative_text"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.title_alternative_text"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <input
                id="acadre-alternative-title"
                v-model="acadreCaseData.title_alternative_text"
                class="mr-4 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                type="text"
                placeholder="Navn på hovedpart"
                name="acadre-alternative-title"
                :disabled="editDisabled"
              >
            </div>
            <!-- Else If Ejendomssagstype 2 -->
            <div 
              v-else-if="caseTypeNeedsProperty()"
              class="flex my-2"
            >
              <label
                for="acadre-alternative-title"
                class="ml-4 mr-6 text-sm font-medium"
              >
                Ejendom valgt:
              </label>
              <div class="ml-4 flex flex-col text-sm">
                <span v-if="ejendomCaseData.adress">
                  {{ ejendomCaseData.adress }}
                </span>
                <span v-if="ejendomCaseData.matrikelnummer">
                  Matrikelnr.: {{ ejendomCaseData.matrikelnummer }}
                </span> 
                <span v-if="ejendomCaseData.bfeNumber">
                  BFE: {{ ejendomCaseData.bfeNumber }} - 
                  <span v-if="ejendomCaseData.ejendomsnr">
                    Ejendomsnr.: {{ ejendomCaseData.ejendomsnr }}
                  </span>
                </span>
              </div>
            </div>
            <!-- Else Emnesagstype 2 -->
            <div
              v-else
              class="flex my-2"
            >
              <label
                for="acadre-alternative-title"
                class="mt-2 w-1/3 ml-4 text-sm font-medium"
              >
                Bemærkning:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.title_alternative_text"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.title_alternative_text"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <input
                id="acadre-alternative-title"
                v-model="acadreCaseData.title_alternative_text"
                class="mr-4 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                type="text"
                placeholder="Bemærkning til sag"
                name="acadre-alternative-title"
              >
            </div>
          </td>
          <td class="w-1/2 table-border-fix">
            <!-- Sagsansvarlig Enhed -->
            <div class="flex my-2">
              <label
                for="acadre-case-title"
                class=" ml-4 w-1/3 text-sm font-medium"
              >
                Sagsansvarlig enhed:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.administrative_unit && acadreCaseData.administrative_unit !== 0"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.administrative_unit || acadreCaseData.administrative_unit === 0"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <div class="mr-4 block w-full text-sm font-medium">
                <TreeSelect 
                  :key="Date.now()"
                  v-model="acadreCaseData.administrative_unit"
                  class="w-full text-sm font-medium"
                  :open-on-click="true"
                  :open-on-focus="true"
                  :searchable="true"
                  :clearable="false"
                  :multiple="false"
                  :allow-clearing-disabled="true"
                  :options="acadreOptions.adminUnit"
                  @input="onAdminUnitSelect"
                />
              </div>
            </div>
          </td>
        </tr>
        <tr> 
          <td class="w-1/2">
            <!-- Sagsindhold -->
            <div class="flex my-2">
              <label
                for="acadre-custom-fields-df1"
                class="mt-2 w-1/3 ml-4 text-sm font-medium"
              >
                Sagsindhold:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.custom_fields_df1"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.custom_fields_df1"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <input
                id="acadre-custom-fields-df1"
                v-model="acadreCaseData.custom_fields_df1"
                class="mr-4 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                type="text"
                placeholder="Indehold af sag"
                name="acadre-custom-fields-df1"
              >
            </div>
          </td>
          <td class="w-1/2">
            <!-- Adgangskode -->
            <div class="flex my-2">
              <label
                for="acadre-access-code"
                class=" ml-4 w-1/3 text-sm font-medium"
              >
                Adgangskode:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.access_code"
                  class="h-2 w-2 text-blue-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.access_code"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <multiselect
                id="acadre-access-code"
                v-model="acadreCaseData.access_code"
                class="mr-4"
                name="acadre-access-code"
                :options="acadreOptions.accessCode"
                :custom-label="customAcadreLabel"
                :multiple="false"
                :allow-empty="false"
                :searchable="false"
                :close-on-select="true"
                placeholder="Vælg adgangskode"
                :option-height="150"
                :disabled="!adminUnitSelected"
                select-label
                deselect-label
                selected-label
              />
            </div>
          </td>
        </tr>
      </table>
      <table class="w-full">
        <tr>
          <td class="w-1/2 table-border-fix">
            <div class="flex my-2">
              <label
                for="acadre-kl-kode"
                class=" ml-4 w-1/3 text-sm font-medium"
              >
                Journalkode:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.emne"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.emne"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <div class="mr-4 block w-full text-sm">
                <TreeSelect 
                  v-model="acadreCaseData.emne"
                  class="w-full text-sm"
                  :open-on-click="true"
                  :open-on-focus="true"
                  :searchable="true"
                  :clearable="false"
                  :multiple="false"
                  placeholder="Vælg KLE Nummer..."
                  :options="acadreOptions.categories.emne"
                  :disable-branch-nodes="true"
                  :max-height="200"
                />
                <span v-if="acadreCaseData.emne" />
              </div>
            </div>
          </td>
          <td class="w-1/2 table-border-fix">
            <div class="flex my-2">
              <label
                for="acadre-facet"
                class=" ml-4 w-1/3 text-sm font-medium"
              >
                Facet:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.facet"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.facet"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <div class="mr-4 block w-full text-sm">
                <TreeSelect 
                  v-model="acadreCaseData.facet"
                  class="w-full text-sm"
                  :open-on-click="true"
                  :open-on-focus="true"
                  :searchable="true"
                  :clearable="false"
                  :multiple="false"
                  placeholder="Vælg Facet"
                  :options="acadreOptions.categories.facet"
                  :disable-branch-nodes="true"
                  :max-height="200"
                  @update:model-value="onFacetChosen"
                />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="w-1/2">
            <div class="flex my-2">
              <label
                for="acadre-restricted-code"
                class=" ml-4 w-1/3 text-sm font-medium"
              >
                Aktindsigt:
              </label>
              <div class="w-4">
                <icon-asterisk
                  v-if="!acadreCaseData.restricted_from_public_text"
                  class="h-2 w-2 text-red-500 mx-1"
                />
                <icon-asterisk
                  v-if="acadreCaseData.restricted_from_public_text"
                  class="h-2 w-2 text-green-500 mx-1"
                />
              </div>
              <multiselect
                id="acadre-restricted-code"
                v-model="acadreCaseData.restricted_from_public_text"
                class="mr-4"
                name="acadre-restricted-code"
                :options="acadreOptions.restrictedCode"
                :custom-label="customTitleAcadreLabel"
                :multiple="false"
                :allow-empty="false"
                :searchable="false"
                :close-on-select="true"
                placeholder="Vælg aktindsigt..."
                :option-height="150"
                select-label
                deselect-label
                selected-label
              />
            </div>
          </td>
          <td class="w-1/2">
            <div class="flex my-2">
              <label
                for="acadre-disposal-code"
                class=" ml-4 mr-6 text-sm font-medium"
              >
                Kassationskode:
              </label>
              <p clsas="ml-4">
                {{ acadreCaseData.case_file_disposal_code.Literal }} - 
                {{ acadreCaseData.case_file_disposal_code.Description }}
              </p>
            </div>
          </td>
        </tr>
      </table>
      <div class="text-red-500 mt-4 font-medium text-sm text-center">
        {{ inputValidationMessage }}
      </div>
      <standard-button
        class="button-create-case"
        button-size="small"
        button-text="Journaliser sag"
        button-icon="pdf"
        button-type="standard"
        @click="createCaseInAcadre()"
      />
      <div
        v-show="isLoading"
        class="loading-overlay"
      >
        <redaction-button-exit
          class="absolute right-0 top-0 m-2"
          @emit-close="closeModal('close')"
        />
        <redaction-icon-spinner class="text-black w-40 h-40" />
      </div>
    </div>
    <div
      v-else
      class="py-2"
    >
      <h2 class="text-center text-red-500 mt-4">
        Systemet kan ikke oprette forbindelse til Acadre
      </h2>
    </div>
  </div>
</template>
  
<script>
import { mapState } from "vuex";
import { reactive } from 'vue';
import Multiselect from "vue-multiselect";
import TreeSelect from '@r2rka/vue3-treeselect'
import StandardButton from "@/components/controls/StandardButton.vue";
import IconAsterisk from "@/components/icons/asterisk.vue";
import buttonExit from "@/components/controls/buttonExit.vue";
import iconSpinner from "@/components/icons/spinner.vue";
import AddressForm from "@/components/forms/AddressForm.vue";

export default {
    name: "ModalExportExternalDocuments",
    components: {
        Multiselect,
        IconAsterisk,
        TreeSelect,
        "redaction-button-exit": buttonExit,
        "redaction-icon-spinner": iconSpinner,
        StandardButton,
    },
    data() {
        return {
            personCaseTypes: [
              'Borgersag',
              'Personalesag',
              'Citizensag',
              'DUBUSag',
              'BUSag',
              'PPRsag',
            ],
            propertyCaseTypes: [
              'Ejendomssag',
            ],
            acadreCaseData:{
                case_file_title_text: "",
                title_alternative_text: "",
                case_file_disposal_code: {
                  Literal: "B",
                  Description: "Bevares",
                  DiscardRuleExpression: null
                },
                case_file_manager_reference: null,
                restricted_from_public_text: {
                  Id: 1,
                  Title: "Lukket" // Lukket i stedet
                },
                administrative_unit: null,
                case_file_type_code: { Description:"Borgersag", Literal:"BGSAG" },
                case_file_status_code: {
                  "Literal": "B",
                  "Description": "Under behandling",
                  "IsClosed": false,
                  "IsExpired": false,
                  "IsTemporary": false
                },
                case_file_paper_storage_indicator: false,
                title_unofficial_indicator: false,
                custom_fields_df1: "Aktindsigtsanmodning / Besvarelse",
                facet: "A53",
                emne: null,
                access_code: null, // Borgersag som standard.
                
            },
            acadreOptions:{
                caseType: [],
                caseStatus: [],
                adminUnit: [],
                userList: [],
                accessCode: [],
                disposalCode: [],
                restrictedCode: [],
                categories: {
                    facet: [],
                    emne: []
                },
                // Document Stuff:
                documentCategory: [],
                documentStatus: [],
                mainDocumentType: [],
                mainDocumentStatus: []
            },
            acadreDocumentData: {
                // document_title_text add to backend
                // description_text add to backend
                document_category_code: { Literal: "Besvarelse", Description: "Besvarelse" }, // Literal, Dokumentkategori
                document_status_code: { Literal: "B", Description: "Behandlet" }, // Literal, Dokumentstatus
                // case_file_reference: str, // Add Later
                registration_date: null, // Journal Dato
                document_date: null, // Dok. Datoer
                // publication_indicator: str, Already exists
                // case_file_manager_reference: {}, Already exists
                document_type_code: {
                  Literal: "U",
                  Description: "Udgående",
                  AssignExternalRecipient: true,
                  AssignInternalRecipient: true,
                  IsExternallyProduced: false,
                  RequiresFollowUp: false
                }, // Literal, 
                record_status_code:{
                  Literal: "J",
                  Description: "Endelig"
                }, // Literal, 
                even_out_required: false,
                // file_name add through backend
                // xml_binary add through backend
            },
            inputValidationMessage: "",
            selectedIndex: null,
            adminUnitSelected: false,
            userSelected: false,
            editDisabled: false,
            isLoading: false,
        };
    },
    computed: {
      ...mapState('acadre', [
        "acadreAdminUnitList",
        "acadreCaseStatusList",
        "acadreCaseTypeList",
        "acadreSearchContactResults",
        "acadreUserList",
        "acadreAccessCodeList",
        "acadreCategoryList",
        "acadreDiscardRuleList",
        "acadreAccessLevelList",
        // "acadreDocumentCategoryList",
        // "acadreDocumentStatusList",
        // "acadreMainDocumentTypeList",
        // "acadreMainDocumentStatusList",
        "acadreConnectionStatus",
        "acadrePrimaryAdminUnit",
        "acadreCreateCaseId",
        "errorMessage",
        "ejendomCaseData",
      ]),
      ...mapState([
          "openProcess",
      ])
    },
    watch: {
      "acadreCaseStatusList"(acadreCaseStatusList) {
        this.acadreOptions.caseStatus = acadreCaseStatusList;
      },
      "acadreAccessCodeList"(acadreAccessCodeList) {
        this.acadreOptions.accessCode = acadreAccessCodeList;
      },
      "ejendomCaseData"(ejendomCaseData) {
        this.acadreCaseData.case_file_title_text = ejendomCaseData.adress
        this.acadreCaseData.title_alternative_text = "Ejendom: " + ejendomCaseData.ejendomsnr
      }
    },
    created() {
      if (this.openProcess.request_subjects[0].cpr) {
        this.acadreCaseData.case_file_title_text = this.openProcess.request_subjects[0].cpr
      }
      if (this.openProcess.request_subjects[0].name) {
        this.acadreCaseData.title_alternative_text = this.openProcess.request_subjects[0].name
      }
    },
    async mounted() {
        this.isLoading = true
        await this.$store.dispatch("acadre/checkAcadreConnection")
        // Check for Acadre Connection
        if (this.acadreConnectionStatus) {
            // Load case status lists
            if (this.acadreCaseStatusList.length === 0) {
                await this.$store.dispatch("acadre/loadCaseStatusList")
            }
            this.acadreOptions.caseStatus = this.acadreCaseStatusList;
            // Load case type list
            if (this.acadreCaseTypeList.length === 0) {
                await this.$store.dispatch("acadre/loadCaseTypeList")
            }
            console.log(this.acadreCaseTypeList)
            this.acadreOptions.caseType = this.acadreCaseTypeList
            // Load Admin Unit (Sagsansvarligenhed)
            if (this.acadreAdminUnitList.length === 0) {
                await this.$store.dispatch("acadre/loadAdminUnitList")
            }
            this.acadreOptions.adminUnit = this.acadreAdminUnitList;
            // Load user List 
            if (this.acadreUserList.length === 0) {
                await this.$store.dispatch("acadre/loadUserList")
            }
            this.acadreOptions.userList = this.acadreUserList
            if (this.openProcess.users[0].acadre_username) {
              const user_found = this.acadreUserList.find(user_found => user_found.Initials.toLowerCase() === this.openProcess.users[0].acadre_username.toLowerCase())
              if (user_found) {
                this.acadreCaseData.case_file_manager_reference = user_found;
                this.onUserSelected()
              }
            }
            // Load categories List
            if (this.acadreCategoryList.length === 0) {
                await this.$store.dispatch("acadre/loadCategoryList")
            }
            this.acadreOptions.categories.emne = this.acadreCategoryList.emne
            this.acadreOptions.categories.facet = this.acadreCategoryList.facet
            // Load disposal list
            if(this.acadreDiscardRuleList.length === 0) {
                await this.$store.dispatch("acadre/loadDiscardRuleList")
            }
            this.acadreOptions.disposalCode = this.acadreDiscardRuleList
            // Load restricted list
            if (this.acadreAccessLevelList.length === 0 ) {
                await this.$store.dispatch("acadre/loadAccessLevelList")
            }
            this.acadreOptions.restrictedCode = this.acadreAccessLevelList
            // Load Document Category List & Status List
            /* if (this.acadreDocumentCategoryList.length === 0) {
                await this.$store.dispatch("acadre/loadAcadreDocumentCategoryList")
            }
            this.acadreOptions.documentCategory = this.acadreDocumentCategoryList */
            /* if (this.acadreDocumentStatusList.length === 0) {
                await this.$store.dispatch("acadre/loadAcadreDocumentStatusList")
            }
            this.acadreOptions.documentStatus = this.acadreDocumentStatusList */
            // Load MainDocument Type List & Status List
            /* if (this.acadreMainDocumentTypeList.length === 0) {
                await this.$store.dispatch("acadre/loadAcadreMainDocumentTypeList")
            }
            this.acadreOptions.mainDocumentType = this.acadreMainDocumentTypeList
            if (this.acadreMainDocumentStatusList.length === 0) {
                await this.$store.dispatch("acadre/loadAcadreMainDocumentStatusList")
            }
            this.acadreOptions.mainDocumentStatus = this.acadreMainDocumentStatusList */
        }
        this.isLoading = false
    },
    methods: {  
        closeModal() {
            this.$emit("close");
        },
        onCaseTypeChanged(newValue) {
          console.log("Changed Value");
          this.acadreCaseData.case_file_title_text = ""
          this.acadreCaseData.case_file_title_text = ""
          if (newValue.Literal === "BGSAG" || newValue.Literal === "PERSAG" || newValue.Literal === "CSAG" || newValue.Literal === "BUSAG" || newValue.Literal === "DUBUSAG") {
            if (this.openProcess.request_subjects[0].cpr) {
              this.acadreCaseData.case_file_title_text = this.openProcess.request_subjects[0].cpr
            }
            if (this.openProcess.request_subjects[0].name) {
              this.acadreCaseData.title_alternative_text = this.openProcess.request_subjects[0].name
            } 
          } else {
            this.acadreCaseData.case_file_title_text = ""
            this.acadreCaseData.title_alternative_text = ""
          }
          this.$store.commit("acadre/setEjendomCaseData", {})
        },
        caseTypeNeedsPerson() {
          return this.personCaseTypes.includes(this.acadreCaseData.case_file_type_code.Description);
        },
        caseTypeNeedsProperty() {
          return this.propertyCaseTypes.includes(this.acadreCaseData.case_file_type_code.Description);
        },
        async createCaseInAcadre() {
          if (this.hasNullOrEmptyValues(this.acadreCaseData)) {
              this.inputValidationMessage = "Sørg veligst for at alle felter er udfyldt før du opretter en sag i Acadre"
          } else {
            this.inputValidationMessage = ""
            const klKodeChosen = this.findObjectById(this.acadreOptions.categories.emne, this.acadreCaseData.emne)
            let customFields;
            let propertyData = undefined;
            if (this.acadreCaseData.case_file_type_code.Literal === "EJSAG" && this.ejendomCaseData) {
              propertyData = {
                street_building_identifier: this.ejendomCaseData.houseNumber,
                street_name: this.ejendomCaseData.streetName,
                post_code: this.ejendomCaseData.postCode,
                city: this.ejendomCaseData.city,
                property_number: this.ejendomCaseData.ejendomsnr,
                matrikel: this.ejendomCaseData.matrikelnummer
              }
              customFields = {
                df1: this.acadreCaseData.custom_fields_df1,
                df3: this.ejendomCaseData.matrikelnummer,
                df4: this.ejendomCaseData.bfeNumber,
              }
            } else {
              customFields = {
                df1: this.acadreCaseData.custom_fields_df1,
              }
            }
            const acadreCaseObject = {
                case_file_title_text: this.acadreCaseData.case_file_title_text,
                title_alternative_text: this.acadreCaseData.title_alternative_text,
                case_file_status_code: this.acadreCaseData.case_file_status_code.Literal, // Unique
                case_file_type_code: this.acadreCaseData.case_file_type_code.Literal, // Unique
                case_file_disposal_code: this.acadreCaseData.case_file_disposal_code.Literal || klKodeChosen.DefaultDiscardRule,
                case_file_manager_reference: this.acadreCaseData.case_file_manager_reference.Id,
                restricted_from_public_text: this.acadreCaseData.restricted_from_public_text.Id,
                administrative_unit: this.acadreCaseData.administrative_unit,
                access_code: this.acadreCaseData.access_code.Code,
                emne: this.acadreCaseData.emne,
                facet: this.acadreCaseData.facet,
                custom_fields: customFields,
                deletion_code: klKodeChosen.DeletionCode || "",
                // Predefined:
                case_file_paper_storage_indicator: this.acadreCaseData.case_file_paper_storage_indicator,
                title_unofficial_indicator: this.acadreCaseData.title_unofficial_indicator,
            }
            const acadreDocumentObject = {
                document_category_code: this.acadreDocumentData.document_category_code.Literal,
                document_status_code: this.acadreDocumentData.document_status_code.Literal,
                publication_indicator: this.acadreCaseData.restricted_from_public_text.Id,
                case_file_manager_reference: this.acadreCaseData.case_file_manager_reference.Id,
                document_type_code: this.acadreDocumentData.document_type_code.Literal,
                record_status_code: this.acadreDocumentData.record_status_code.Literal,
                process_id: this.$route.params.processId
            }
            const processData = {
              decision_letter_id: this.openProcess.decision_letter.id,
              process: {
                id: this.openProcess.id,
                process_id: this.openProcess.process_id
              }
            }
            const succesCreation = await this.$store.dispatch("acadre/createCase", {caseData: acadreCaseObject, documentData: acadreDocumentObject, processData: processData, propertyData: propertyData});
            if (succesCreation) {
              this.closeModal()
            } else {
              if (this.errorMessage) {
                this.inputValidationMessage = this.errorMessage;
              } else {
                this.inputValidationMessage = "Der gik noget galt med journalisering af en Acadre sag. Kontakt Aktio hvis det fortsat fejler"
              }
            }
          }
        },
        customAcadreLabel(option) {
            return option.Description
        },
        customTitleAcadreLabel(option) {
            return option.Title
        },
        customUserListAcadreLabel(option) {
            return option.Name
        },
        customAcadreLabelDocuments(option) {
            return option.Literal + " - " + option.Description
        },
        onAdminUnitSelect() {
            this.adminUnitSelected = true
            if (this.userSelected && this.adminUnitSelected) {
                this.loadAccessCodeList();
            }
        },
        async onUserSelected() {
          this.userSelected = true
          this.acadreCaseData.access_code = null
          await this.$store.dispatch("acadre/getUserPrimaryAdminUnit", this.acadreCaseData.case_file_manager_reference.Id)
          this.acadreCaseData.administrative_unit = await this.acadrePrimaryAdminUnit.Id
          this.adminUnitSelected = true
          if (this.userSelected && this.adminUnitSelected) {
               this.loadAccessCodeList();
          }
        },
        async onFacetChosen(value) {
          const chosenFacetObject = this.findObjectById(this.acadreOptions.categories.facet, value)
          this.acadreCaseData.case_file_disposal_code = this.acadreOptions.disposalCode.find(obj => obj.Literal === chosenFacetObject.DefaultDiscardRule)
        },
        async loadAccessCodeList() {
            const accessCodeObject = {
                user_id: this.acadreCaseData.case_file_manager_reference.Id,
                admin_unit_id: this.acadreCaseData.administrative_unit
            }
            await this.$store.dispatch("acadre/loadAccessCodeList", accessCodeObject)
            this.acadreOptions.accessCode = this.acadreAccessCodeList;
            this.acadreCaseData.access_code = this.acadreAccessCodeList[0];
        },
        findObjectById(array, id) {
            for (const item of array) {
                if (item.id === id) {
                    return item;
                }
                if (item.children) {
                    const foundInChildren = this.findObjectById(item.children, id);
                    if (foundInChildren) {
                        return foundInChildren;
                    }
                }
            }
            return undefined;
        },
        hasNullOrEmptyValues(obj) {
            for (const key in obj) {
                // Adjusted check to comply with no-prototype-builtins rule
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    const value = obj[key];

                    // Check for null or empty object
                    if (value === null || (typeof value === 'object' && Object.keys(value).length === 0)) {
                        return true;
                    }

                    // Check for empty string, but allow the value 0
                    if (value === '' && value !== 0) {
                        return true;
                    }
                }
            }
            return false;
        },
        async openAddressModal() {
          await this.$modal.show(
            AddressForm,
            {},
            {
              classes: 'overflow-visible',
              width: "40%",
              height: "490px",
              overflowY: "initial",
              scrollable: true,
              draggable: false,
              clickToClose: true,
            }
          );
        },
    },
};
</script>

<style>
thead {
    height: 40px;
}
.table-responsive {
    max-height: 350px;
    overflow-y: auto;
}
.table-line-seperator {
    border-right: 0px solid #c8c8c8;
}
.selected-row-contact {
    background-color: #f0f0f0;
}
.selected-row-contact::after {
    content: '✓';
    color: #00af32;
    font-size: 20px;
    position: absolute;
  }
.curser-pointer {
    cursor: pointer;
}
.button-create-case {
    position: absolute;
    bottom: 30px;
    right: 40px;
}
.table-border-fix {
    border-collapse: initial;
    white-space: normal;
    overflow: visible;
    text-overflow: clip; 
}
.vue-treeselect__label {
    white-space: normal;
}
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.17);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
</style>